import * as React from "react"
import {
  Box,
  Flex,
  Input,
  InputProps,
  Label,
  Paragraph,
  ThemeUIStyleObject,
} from "theme-ui"
import { Collapse } from "react-collapse"

import { Icon } from "./Icon"

export interface CheckboxProps extends InputProps {
  label?: React.ReactNode
  labelStyle?: ThemeUIStyleObject
  size?: "sm" | "md"
  subContent?: React.ReactNode
  subContentVisibilityReversed?: boolean
  defaultChecked?: boolean
  orientation?: "start" | "end"
  isDisabled?: boolean
  hasError?: boolean
  errorMessage?: string
  errorMessageStyle?: ThemeUIStyleObject
}

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  label,
  labelStyle,
  size = "sm",
  subContent,
  subContentVisibilityReversed = false,
  defaultChecked = false,
  orientation = "start",
  isDisabled,
  hasError,
  errorMessage,
  errorMessageStyle,
  sx,
  ...inputProps
}) => {
  const [isChecked, setIsChecked] = React.useState(defaultChecked)

  return (
    <Box sx={{ ...sx }}>
      <Label
        sx={{
          display: "block",
          cursor: isDisabled ? "not-allowed" : "pointer",
          WebkitTapHighlightColor: "transparent",
        }}
      >
        <Flex>
          <Input
            type="checkbox"
            checked={isChecked}
            disabled={isDisabled}
            sx={{
              position: "absolute",
              width: "1px",
              height: "1px",
              border: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              clip: "rect(0px, 0px, 0px, 0px)",
              padding: 0,
              margin: "-1px",
            }}
            {...inputProps}
            onChange={(event) => {
              setIsChecked(event.target.checked)
              inputProps.onChange && inputProps.onChange(event)
            }}
          />
          <Box
            sx={{
              order: orientation === "start" ? 0 : 1,
              width: size === "sm" ? [4, 3] : 4,
              height: size === "sm" ? [4, 3] : 4,
              color: isChecked && "grayscale.white",
              border: "1px solid",
              borderColor: isDisabled
                ? "grayscale.600"
                : hasError
                ? "red"
                : "primary",
              backgroundColor:
                isChecked && isDisabled
                  ? "grayscale.600"
                  : isChecked && "primary",
              position: "relative",
              transition: "background-color .2s",
              marginBlockStart: size === "sm" ? ["1px", 1] : "2px",
              marginInlineStart: orientation === "end" && 3,
              marginInlineEnd: orientation === "start" && 3,
            }}
          >
            <Icon
              name="checkmark"
              size={size === "sm" ? [4, 3] : 4}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                opacity: isChecked ? 1 : 0,
                transform: "translateX(-50%) translateY(-50%)",
                transition: "opacity .2s",
              }}
            />
          </Box>
          {label && (
            <Paragraph
              sx={{
                flex: 1,
                fontSize: "sm",
                color: hasError ? "red" : undefined,
                ...labelStyle,
              }}
            >
              {label}
            </Paragraph>
          )}
        </Flex>
      </Label>
      {subContent && (
        <Box
          sx={{
            flex: 1,
            cursor: isDisabled ? "not-allowed" : "pointer",
            opacity: subContentVisibilityReversed
              ? !isChecked
                ? 1
                : 0
              : isChecked
              ? 1
              : 0,
            visibility: subContentVisibilityReversed
              ? !isChecked
                ? "visible"
                : "hidden"
              : isChecked
              ? "visible"
              : "hidden",
            transition:
              "opacity cubic-bezier(.5, .5, 0, 1) .4s .4s, visibility cubic-bezier(.5, .5, 0, 1) .4s .4s",
          }}
        >
          <Collapse
            isOpened={subContentVisibilityReversed ? !isChecked : isChecked}
          >
            {subContent}
          </Collapse>
        </Box>
      )}
      {errorMessage && (
        <Paragraph
          sx={{
            fontSize: "sm",
            color: "red",
            marginInlineStart: [7, 6],
            marginBlockStart: 1,
            ...errorMessageStyle,
          }}
        >
          {errorMessage}
        </Paragraph>
      )}
    </Box>
  )
}
